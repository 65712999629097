/* .bank-info {
  display: flex;
  align-items: center;
  gap: 20px;
} */

.section_title {
  font-size: 26px;
  font-weight: 700;
  position: relative;
  text-align: center;
  color: var(--text-color);
}

.section_title:before {
  margin: 0 15px 10px 0;
}

.section_title:after {
  margin: 0 0 10px 15px;
}

.section_title:before,
.section_title:after {
  content: "";
  width: 50px;
  height: 2px;
  background: var(--text-color);
  display: inline-block;
}

.bank-info {
  display: flex;
  /* align-items: flex-start; */
  align-items: end;
  gap: 20px;
}

.bank-info-wrap {
  border-radius: 15px;
  padding: 15px 10px 45px 10px;
  /* border-top: 8px solid #b5090e; */
  border-top: 8px solid #08416b;
  border-left: 1px solid #25618c;
  border-right: 1px solid #25618c;
  border-bottom: 1px solid #25618c;
  position: relative;
  z-index: 0;
  /* width: max-content; */
}

.bank-details {
  color: var(--global-color);
  /* color: var(--text-color); */
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 14px 0px 20px 0px;
}

.bank-info .info-text li {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  padding: 0px 0px 10px 0px;
  /* width: max-content; */
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.bank-info .info-details li {
  /* color: var(--text-color); */
  /* color: #777; */
  color: #777777eb;
  font-size: 16px;
  font-weight: 600;
  padding: 0px 0px 10px 0px;
  /* width: max-content; */
  word-wrap: break-word;
  overflow-wrap: break-word;
  letter-spacing: 0.2px;
}

.bank-info .info-text,
.bank-info .info-details {
  max-width: 362px;
}

.donors-btn {
  float: right;
  border: 0;
  margin: 0px 10px 10px 0px;
  background-color: var(--text-color);
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.5px;
  padding: 10px;
  border-radius: 4px;
}

@media (min-width: 200px) and (max-width: 450px) {
  .bank-info {
    /* align-items: baseline; */
  }

  .bank-details {
    font-size: 18px;
  }

  .bank-info .info-text,
  .bank-info .info-details {
    width: max-content;
  }

  .bank-info .info-text li {
    font-size: 12px;
  }

  .bank-info .info-details li {
    font-size: 12px;
    width: 200px;
  }

  .section_title {
    font-size: 18px;
  }

  .section_title:before,
  .section_title:after {
    content: "";
    width: 30px;
    height: 2px;
    background: var(--text-color);
    display: inline-block;
  }

  .section_title:before {
    margin: 5px 5px 5px 0px;
  }

  .section_title:after {
    margin: 5px 5px 5px 5px;
  }

  /* .section_title:before {
    margin: 0px 6px 6px 0px;
  } */
  /* .section_title:after {
    margin: 0px 0 6px 6px;
  } */
}

@media (min-width: 451px) and (max-width: 575.99px) {
  .bank-details {
    font-size: 18px;
  }

  .bank-info .info-text,
  .bank-info .info-details {
    /* width: max-content; */
  }

  .bank-info .info-text li {
    font-size: 15px;
  }

  .bank-info .info-details li {
    font-size: 15px;
    width: 255px;
  }

  .section_title {
    font-size: 20px;
  }

  .section_title:before {
    margin: 5px 5px 5px 0;
  }

  .section_title:after {
    margin: 5px 0px 5px 5px;
  }
}

@media (min-width: 577px) and (max-width: 768.99px) {
  .bank-info .info-details li {
    font-size: 16px;
    width: 300px;
  }
}

@media (min-width: 769px) and (max-width: 1199.98px) {
  .bank-info .info-details li {
    /* width: max-content; */
    /* width: 600px; */
  }
}